.leaflet-container {
  width: 100%;
  height: 100vh;
}


.marcador-cluster-agendado {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
  color: white;
  line-height: 32px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  background-color: #24ab63;
}

.marcador-cluster-vencido {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
  color: white;
  line-height: 32px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  background-color: #90423b;
}

.marcador-cluster-aguardando {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
  color: white;
  line-height: 32px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  background-color: #2c84bc;
}


/* tiver os tres */
.marcador-cluster-tres {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50%;
  color: white;
  line-height: 32px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  background-color: #ffc400;
}

/* ferramentas */

.leaflet-left {
  left: 0;
}

.leaflet-top {
  top: 60px;
}
.leaflet-bottom,
.leaflet-top {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-bar .easy-button-button,
.leaflet-bar>button,
.leaflet-touch .easy-button-button,
.leaflet-touch>button {
  width: 40px !important;
  height: 40px !important;
  border-radius: 2px !important;
}

.leaflet-touch, .leaflet-bar {
  margin: 0;
  padding: 0;
}

.leaflet-touch a,
.leaflet-bar a {
  width: 40px !important;
  height: 40px !important;
  border-radius: 2px !important;
}

.leaflet-touch > button,
.leaflet-touch .easy-button-button,
.leaflet-bar > button,
.leaflet-bar .easy-button-button {
  width: 40px !important;
  height: 40px !important;
  border-radius: 2px !important;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out,
.leaflet-bar .leaflet-control-zoom-in,
.leaflet-bar .leaflet-control-zoom-out {
  line-height: 40px !important;
}

.leaflet-touch .leaflet-control-fullscreen a,
.leaflet-bar .leaflet-control-fullscreen a {
  background-position: 7px 9px;
}

.leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a,
.leaflet-bar.leaflet-fullscreen-on .leaflet-control-fullscreen a {
  background-position: 7px -21px;
}

.leaflet-bar {
  border-radius: 2px !important;
}

.leaflet-bar a {
  background-position: 7px 9px !important;
}